import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, Card, CardContent, Stack } from "@mui/material";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { SignInButton } from "./SignInButton";
import logo from "/bb_logo.png";

/* function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
} */

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  /*   const { instance } = useMsal();
   */
  /*   useEffect(() => {
    if (!isAuthenticated) {
      handleLogin(instance);
    }
  }, [isAuthenticated]); */

  useEffect(() => {
    try {
      if (isAuthenticated) {
        if (accounts.length > 0) {
          const userDetails = accounts[0];
          Sentry.setUser({
            id: userDetails.localAccountId,
            email: userDetails.username,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated ? (
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgb(12, 34, 67)",
          }}
        >
          <Card sx={{ minWidth: 225 }} raised>
            <CardContent>
              <Stack spacing={6} alignItems="center">
                <img src={logo} alt="logo" width="100" />
                <SignInButton />
              </Stack>
            </CardContent>
          </Card>
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  children: PropTypes.node,
};
